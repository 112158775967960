@import "../variables.modules";

footer {
  color: $dark;
}

.dark {
  background-color: $dark;
  color: $white;
  transition: all 400ms;

  a,
  i,
  li {
    color: $white;
    transition: color 400ms;
  }

  a:visited {
    color: $white;
  }

  footer {
    color: $white;
  }
}

.light {
  background-color: $white;
  color: $dark;
  transition: all 400ms;
}
